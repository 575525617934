import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '@Services/permission/permission.service';

export const PermissionGuard: CanActivateFn = (route) => {
  const hasPermission = inject(PermissionService).hasPermission(route.data['permission']);
  if (hasPermission) {
    return true;
  } else {
    inject(Router).navigate(['/error/403']);
    return false;
  }
};
